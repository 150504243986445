import React, { useEffect, useState, useRef  } from 'react';
import './App.css';
import mp3_file from './music.mp3';
import ModalVideo from 'react-modal-video';

import Lightbox from 'yet-another-react-lightbox';
import { slides, detail_slides } from './data.ts';
import 'yet-another-react-lightbox/styles.css';
import {
  // Captions,
  // Download,
  Fullscreen,
  // Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Images from './Image.tsx';
import ReactPlayer from 'react-player/youtube'
import axios from 'axios';

function App() {
  // State to track if the music is playing or paused
  const [isPlaying, setIsPlaying] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isOpenYoutube, setIsOpenYoutube] = useState(false);

  // Reference to the audio element
  const audioRef = useRef(null);
  const donatePopupRef = useRef(null);
  const youtubePopupRef = useRef(null);
  const [activeItem, setActiveItem] = useState(null);
  const [index, setIndex] = useState(-1);
  const [isMoblie, setIsMobile] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    // Load external scripts and initialize them
    const loadScripts = () => {
      const scripts = [
        // 'js/jquery.easing.1.3.js',
        // 'js/bootstrap.min.js',
        // 'js/jquery.waypoints.min.js',
        // 'js/owl.carousel.min.js',
        // 'js/jquery.countTo.js',
        // 'js/jquery.ajaxchimp.js',
        // 'js/jquery.stellar.min.js',
        // 'js/jquery.magnific-popup.min.js',
        'js/magnific-popup-options.js',
        // 'js/simplyCountdown.js',
        'js/main.js',
        // 'js/jquery.style.switcher.js',
      ];

      // Track the number of scripts loaded
      let loadedScripts = 0;

      // Function to execute when all scripts are loaded
      const allScriptsLoaded = () => {
        loadedScripts++;
        if (loadedScripts === scripts.length) {
          initializeSimplyCountdown();
        }
      };

      scripts.forEach(script => {
        const scriptTag = document.createElement('script');
        scriptTag.src = script;
        scriptTag.async = false; // Ensures scripts are loaded sequentially
        scriptTag.onload = allScriptsLoaded; // Trigger allScriptsLoaded when script is loaded
        document.body.appendChild(scriptTag);
      });
    };

    const initializeSimplyCountdown = () => {
      const countdownElement = document.querySelector('.simply-countdown-one');
      if (countdownElement && countdownElement.childNodes.length === 0 && window.simplyCountdown) {
        var d = new Date(new Date().getTime() + 200 * 120 * 120 * 2000);
        window.simplyCountdown('.simply-countdown-one', {
          year: d.getFullYear(),
          month: 4,
          day: 24,
        });
      }
    };

    loadScripts();
  }, []);

  useEffect(() => {

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (donatePopupRef.current && event.target.contains(donatePopupRef.current)) {
        setDialogOpen(false);
      }
      if (youtubePopupRef.current && event.target.contains(youtubePopupRef.current)) {
        setIsOpenYoutube(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [donatePopupRef]);

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setIsMobile(true);
    }

    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to handle toggling play/pause
  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause(); // Pause the audio
    } else {
      audioRef.current.play(); // Play the audio
    }
    setIsPlaying(!isPlaying); // Toggle the state
  };

  const giftOpen = () => {
    setDialogOpen(true);
  }

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);

    if (itemName === 'mungcuoi') {
      setDialogOpen(true)
    }
    if (itemName === 'sukien') {
      const section = document.querySelector( '#fh5co-event' );
      section.scrollIntoView( { behavior: 'smooth' } );
    }
    if (itemName === 'capdoi') {
      const section = document.querySelector( '#fh5co-couple' );
      section.scrollIntoView( { behavior: 'smooth' } );
    }
    if (itemName === 'chuyentinh') {
      const section = document.querySelector( '#fh5co-couple-story' );
      section.scrollIntoView( { behavior: 'smooth' } );
    }
    if (itemName === 'albumcuoi') {
      const section = document.querySelector( '#fh5co-gallery' );
      section.scrollIntoView( { behavior: 'smooth' } );
    }
    if (itemName === 'loichuc') {
      const section = document.querySelector( '#fh5co-testimonial' );
      section.scrollIntoView( { behavior: 'smooth' } );
    }
  };

  const openYoutube = () => {
    setIsOpenYoutube(true);
    setIsPlaying(false);
    audioRef.current.pause();
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const submitMessage = async () => {
    setStatus("Đang gửi...")

    if (name === "" || email === "" || message === "") {
      setStatus("Vui lòng thử lại sau")
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    let data = {
      name: name,
      email: email,
      message: message,
    };

    try {
      const result = await axios.post(`https://us-central1-noithatai.cloudfunctions.net/app/api/wd-message`, data = {data}, { headers });
    } catch (error) {
      setStatus('Lỗi thông tin gửi lên')
      return;
    }

    setTimeout(() => {
      setStatus("Gửi thành công!")
    }, 2000);
  }
  return (
    <div className="App">
      <>
        <div className="fh5co-loader" />
        <div id="page">
          {isMoblie &&
            <a href="#" class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white"><i></i></a>
          }
          <nav className="fh5co-nav" role="navigation">
            <div className="container">
              <div className="row">
                <div className="col-xs-2">
                  <div id="fh5co-logo">
                    <a href="/">
                      Wedding<strong>.</strong>
                    </a>
                  </div>
                </div>
                <div className="col-xs-10 text-right menu-1">
                  <ul>
                    <li className={activeItem === 'sukien' ? 'active' : ''} onClick={() => handleItemClick('sukien')}>
                      <a style={{cursor: "pointer"}}>Lễ cưới</a>
                    </li>
                    <li className={activeItem === 'capdoi' ? 'active' : ''} onClick={() => handleItemClick('capdoi')}>
                      <a style={{cursor: "pointer"}}>Cặp đôi</a>
                    </li>
                    <li className={activeItem === 'albumcuoi' ? 'active' : ''} onClick={() => handleItemClick('albumcuoi')}>
                      <a style={{cursor: "pointer"}}>Album cưới</a>
                    </li>
                    <li className={activeItem === 'mungcuoi' ? 'active' : ''} onClick={() => handleItemClick('mungcuoi')}>
                      <a style={{cursor: "pointer"}}>Mừng cưới</a>
                    </li>
                    <li className={activeItem === 'loichuc' ? 'active' : ''} onClick={() => handleItemClick('loichuc')}>
                      <a style={{cursor: "pointer"}}>Lời chúc</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div id="fh5co-offcanvas">
            <ul>
              <li className={activeItem === 'sukien' ? 'active' : ''}>
                <a style={{cursor: "pointer"}}>Lễ cưới</a>
              </li>
              <li className={activeItem === 'capdoi' ? 'active' : ''}>
                <a style={{cursor: "pointer"}}>Cặp đôi</a>
              </li>
              <li className={activeItem === 'albumcuoi' ? 'active' : ''}>
                <a style={{cursor: "pointer"}}>Album cưới</a>
              </li>
              <li className={activeItem === 'mungcuoi' ? 'active' : ''} onClick={() => handleItemClick('mungcuoi')}>
                <a style={{cursor: "pointer"}}>Mừng cưới</a>
              </li>
              <li className={activeItem === 'loichuc' ? 'active' : ''}>
                <a style={{cursor: "pointer"}}>Lời chúc</a>
              </li>
            </ul>
          </div>
          <header id="fh5co-header" className="fh5co-cover" role="banner" style={{ backgroundImage: 'url(images/couple_landing.webp)' }}>
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center">
                  <div className="display-t">
                    <div className="display-tc animate-box" data-animate-effect="fadeIn">
                      <h1>An &amp; Thao</h1>
                      {/* <h2>We Are Getting Married</h2> */}
                      <h2>SAVE THE DATE</h2>
                      {/* <h2>24 April 2024</h2> */}
                      <div className="simply-countdown simply-countdown-one" />
                      {/* <p>
                        <a href="#" className="btn btn-default btn-sm">
                          Save the date
                        </a>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div id="fh5co-couple">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                  <h2>Co dau & Chu re</h2>
                  {/* <h3>April 24th, 2024 Dak Lak</h3> */}
                  <p>Sơ yếu lý lịch</p>
                </div>
              </div>
              <div className="couple-wrap animate-box">
                <div className="couple-half">
                  <div className="groom">
                    <img src="images/groom.webp" alt="groom" className="img-responsive" />
                  </div>
                  <div className="desc-groom">
                    <h3>An Phung</h3>
                    <p>
                      Là một thợ đụng hoạt động chìm trong lĩnh vực IT, sau nhiều năm tích cực lặn, chú rể đã trở thành 1 báo thủ phần mềm xuyên biên giới.
                    </p>
                  </div>
                </div>
                <p className="heart text-center">
                  <i className="icon-heart2" />
                </p>
                <div className="couple-half">
                  <div className="bride">
                    <img src="images/bride.webp" alt="groom" className="img-responsive" />
                  </div>
                  <div className="desc-bride">
                    <h3>Thao Luong</h3>
                    <p>
                      Là một cô gái hướng “lung tung" có niềm đam mê mãnh liệt với ẩm thực, với kinh nghiệm dày dặn trong việc ăn uống, cô đã trở thành một gen Z văn phòng ăn hàng nhất Nam Trung Bộ.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="fh5co-event" className="fh5co-bg" style={{ backgroundImage: 'url(images/img_bg_3.jpg)' }}>
            <div className="bg-overlay" />
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                  <span>Our Special Events</span>
                  <h2>Le cuoi</h2>
                </div>
              </div>
              <div className="row">
                <div className="display-t">
                  <div className="display-tc">
                    <div className="col-md-10 col-md-offset-1">
                      <div className="col-md-6 col-sm-6 text-center">
                        <div className="event-wrap animate-box">
                          <h3>Tiệc Cưới Nhà Gái</h3>
                          <div className="event-col">
                            <i className="icon-clock" /> <span>11:00</span>
                            {/* <span>6:00 PM</span> */}
                          </div>
                          <div className="event-col">
                            <i className="icon-calendar" /> <span>24/04/2024</span>
                            {/* <span>November, 2016</span> */}
                          </div>
                          {/* <p>
                            Hôn lễ tổ chức tại Tư gia lúc 9:00 cùng ngày. 
                          </p> */}
                          <p>
                            Địa điểm dự tiệc: Nhà hàng Dũng Huyền – Chợ 49, Thôn 3, xã Phú Xuân, huyện Krông Năng, tỉnh Đăk Lăk.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 text-center">
                        <div className="event-wrap animate-box">
                          <h3>TIỆC CƯỚI NHÀ TRAI</h3>
                          <div className="event-col">
                            <i className="icon-clock" /> <span>11:00</span>
                            {/* <span>12:00 AM</span> */}
                          </div>
                          <div className="event-col">
                          <i className="icon-calendar" /> <span>27/04/2024</span>
                            {/* <span>November, 2016</span> */}
                          </div>
                          <p>
                            Địa điểm dự tiệc: Sân kho sản phẩm Công ty cà phê 49, xã Phú Xuân, huyện Krông Năng, tỉnh Đăk Lăk.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="fh5co-couple-story">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                  <span>We Love Each Other</span>
                  <h2>Hanh trinh yeu</h2>
                  {/* <p>
                    Yêu thương, chia sẻ, cùng nhau nhìn về một hướng 💪
                  </p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-md-offset-0">
                  <ul className="timeline animate-box">
                    <li className="animate-box">
                      <div className="timeline-badge" style={{ backgroundImage: 'url(images/first_meet.webp)' }} />
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <p className="timeline-title">À lôiii</p>
                          <span className="date">2013</span>
                        </div>
                        <div className="timeline-body">
                          <p>
                            Gặp nhau lần đầu tại quán trà sữa “Cỏ 3 Lá” – Love at the first sight.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted animate-box">
                      <div className="timeline-badge" style={{ backgroundImage: 'url(images/married.webp)' }} />
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <p className="timeline-title">Đi tới hôn nhân</p>
                          <span className="date">2024</span>
                        </div>
                        <div className="timeline-body">
                          <p>
                            Cảm ơn em luôn bên anh suốt hơn 10 năm qua, đặc biệt là lúc anh chưa có gì và tồi tệ nhất ❤️
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="fh5co-gallery" className="fh5co-section-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
                  <span>Our Memories</span>
                  <h2>Album Cuoi</h2>
                  <p>
                    Mời mọi người cùng xem ảnh cưới của chúng mình nhé!
                  </p>
                </div>
              </div>
              <div className="row row-bottom-padded-md">
                <div className="col-md-12">
                  {/* <ul id="fh5co-gallery-list">
                    <li
                      className="one-third animate-box"
                      data-animate-effect="fadeIn"
                      style={{ backgroundImage: 'url(images/gallery-1.jpg)' }}
                    >
                      <a href="images/gallery-1.jpg" className="popup-vimeo" />
                    </li>
                    <li
                      className="one-third animate-box"
                      data-animate-effect="fadeIn"
                      style={{ backgroundImage: 'url(images/gallery-1.jpg)' }}
                    >
                      <a href="images/gallery-1.jpg" className="popup-vimeo" />
                    </li>
                    <li
                      className="one-third animate-box"
                      data-animate-effect="fadeIn"
                      style={{ backgroundImage: 'url(images/gallery-1.jpg)' }}
                    >
                      <a href="images/gallery-1.jpg" className="popup-vimeo" />
                    </li>
                  </ul> */}
                  <Images
                    data={slides}
                    onClick={(currentIndex) => setIndex(currentIndex)}
                  />

                  <Lightbox
                    plugins={[Fullscreen, Zoom]}
                    captions={{
                      showToggle: true,
                      descriptionTextAlign: 'end',
                    }}
                    // open={open}
                    // close={() => setOpen(false)}

                    index={index}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    slides={detail_slides}
                  />
                    <div className="col-md-12 col-sm-12 text-center send-btn">
                      <div className="col-md-6 col-sm-6">
                        <button type="submit" className="btn more-btn" onClick={() => setIndex(0)}>
                          Xem tất cả
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div id="fh5co-services" className="fh5co-section-gray">
            <div className="container">
              <div className="row animate-box">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                  <h2>Video Cuoi</h2>
                  <p>
                    Còn đây là video cưới của chúng mình.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 animate-box">
                  <div
                    className="fh5co-video fh5co-bg"
                    style={{
                      backgroundImage: 'url(images/married.webp)',
                      display: 'fixed',
                    }}
                  >
                    {isMoblie ? (
                    <a onClick={openYoutube} className="popup-youtube">
                      <i className="icon-video2"/>
                    </a>
                    ) : (
                      <a href='https://www.youtube.com/watch?v=taf5psH3c8s' className="popup-youtube" onClick={() => {setIsPlaying(false); audioRef.current.pause();}}>
                      <i className="icon-video2"/>
                    </a>
                    )}
                    <div className="overlay" />
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-12 animate-box">
                  <div
                    className="fh5co-video fh5co-bg"
                    style={{
                      backgroundImage: 'url(images/married.webp)',
                      position: 'relative', // change from 'fixed' to 'relative'
                      overflow: 'hidden', // hide overflowing content
                      cursor: 'pointer', // change cursor to pointer for better UX
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    <i className="icon-video2" style={{ color: '#fff', fontSize: '4rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                    <div className="overlay" />
                  </div>
                </div>
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="taf5psH3c8s"
                  onClose={() => setIsOpen(false)}
                />
              </div> */}
            </div>
          </div>
          <div id="fh5co-testimonial">
            <div className="container">
              <div className="row">
                <div className="row animate-box">
                  <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                    <span>Best Wishes</span>
                    <h2>So luu but</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 animate-box">
                    <div className="wrap-testimony">
                      <div className="wrap-testimony-list">
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            <figure>
                              <img src="images/namluu.webp" alt="user" />
                            </figure>
                            <span>
                              Nam Lưu
                            </span>
                            <blockquote>
                              <p>
                                "Khiếp thặc đấy !!!! Chị báo ngày cưới mà em không tin nổi luông. Em cứ ngỡ mình còn trẻ trung ấy 😝😝😝 ngỡ như mình còn mới bước vào thời đại học , ngỡ như mình còn í ới gọi nhau đi trà tắc, lê la vỉa hè 😝😝😝
                              </p>
                              <p>
                                Thôi thì quãng thời gian chị và anh An quen nhau cũng đủ lâu để chị thấy an tâm về cuộc sống sau này. Chúc chị sẽ luôn vui vẻ, mạnh khoẻ và hạnh phúc với mối tình của chị 
                                HAPPY WEDDING ❤️❤️❤️❤️."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            <figure>
                              <img src="images/mary.webp" alt="user" />
                            </figure>
                            <span>
                              Mary
                            </span>
                            <blockquote>
                              <p>
                                "Hé lu anh An và Bèo đầu bết!
                              </p>
                              <p>
                                Vậy là thành người có chồng có vợ cả rồi hee, nhưng mà còn thiếu "Phúc- Lộc- Thọ" đấy nhá. Ráng ráng săn bắt hái lượm lẹ lẹ cho em bế cháu nhe 😆.
                              </p>
                              <p>
                              Chúc cho hành trình mới của cô chú luôn sẽ có nhau, cùng nhau đi hết trái đất này. Mãi mãi hạnh phúc như hồi mới iuuu!"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure>
                            <span>
                              Thảo & Bean
                            </span>
                            <blockquote>
                              <p>
                                "Chúc Chị bé của em sẽ là cô dâu xinh đẹp và hạnh phúc nhất trên đời này. Mọi điều tốt đẹp sẽ luôn đến với Anh Chị và yêu thương Anh Chị thật nhiềuuu. 
                              </p>
                              <p>
                                We wish my lovely sister will definitely be our most beautiful and happinest bride in this world. We hope nothing but the best to our cute couple. We honestly love you guys…. 🫶🏻
                              </p>
                              <p>
                                P.s: Totally be happy forever!!!!!"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Hoài Thương,
                            </span>
                            <span>
                            ngừi bạn dễ thương 🥰🥰🥰
                            </span>
                            <blockquote>
                              <p>
                                "Trời ơi, con bạn khó ở của mình cuối cùng cũng chịu lấy ck. Tui chỉ mong bạn luôn luôn cừoi, luôn luôn hạnh phúc và không phải suy nghĩ vớ vẩn những chuyện lung tung nữa. 
                              </p>
                              <p>
                              Thành tâm chúc 2 vkck m hạnh phúc viên mãn. Chuẩn bị đón 1 hành trình mới, 1 trang mới nhưng sẽ rất thú vị đây 🥰🥰🥰. Mãi iu bff 🥰."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Hảo Xinh ^.^
                            </span>
                            <blockquote>
                              <p>
                                "Nhớ ngày nào còn được làm tệp đính kèm để đi hẹn hò ké vậy mà giờ người ta cưới tui không về dự được 🥲🥲🥲
                              </p>
                              <p>
                              Chúc mừng 11 năm cố gắng nay đã đơm hoa kết trái nhé ạ 🥰."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Phucphamkuzon
                            </span>
                            <blockquote>
                              <p>
                                "Chúc bạn và cô em gái Thảo trăm năm hạnh phúc nhé. Mới ngày nào mình đạp cà đạp tiêu đi ăn uống kara trà sữa các kiểu con đà điểu cùng. Cuối cùng đã gặt được kết quả vô cùng tốt đẹp.
                              </p>
                              <p>
                               Chúc gđ nhỏ mãi hạnh phúc nha."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            VOV
                            </span>
                            <blockquote>
                              <p>
                                "Thay mặt nhóm VOV chúc Bạn mình trăm năm hạnh phúc nhoé. Mãi iu ❤️❤️❤️
                              </p>
                              <p>
                                kí tên Lan Anh & Bích Phương."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                              Chị Phạm Hương
                            </span>
                            <blockquote>
                              <p>
                                "Chị xem tấm hình cưới mà bất ngờ cực kỳ, biết từ lúc tụi em bắt đầu yêu tới giờ. Chị vui lắm luôn. 2 vợ chồng quá đẹp đôi, chúc 2 em hạnh phúc viên mãn 😘❤️❤️."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Thanh Tú
                            </span>
                            <blockquote>
                              <p>
                                "Chúc sư phụ cà chị Thảo Xinh hạnh phúc bên nhau thêm rất rất rất nhiều cái 10 năm nữa nhe 😍."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            BichPhuongnehihi
                            </span>
                            <blockquote>
                              <p>
                                "Mới ngày nào còn chờ bạn lén bome đi gặp ng iuu mà giờ đã thành đôi thật sự rồi, may quá không uổng công mình.kaka Thay mặt trưởng nhóm “đánh bài online”, “tin tức VOV”, “ đầu bếp mùa dịch” chúc hai bạn mãi yêu thương nhau và hạnh phúc như vậy  nhé ạ! Mãi iuuu 😍
                              </p>
                              <p>Bíchhhh Phương xinh đẹp 🌝."</p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Mai Anh
                            </span>
                            <blockquote>
                              <p>
                                "Tu trăm năm mới chung thuyền. Tu ngàn năm mới nên duyên vợ chồng”. Chúc huynh và em Thảo sẽ có một hành trình mới thật tuyệt vời"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Huyềnnnnn A1 
                            </span>
                            <blockquote>
                              <p>
                                "Chúc mừng hạnh phúc tân lang - tân nương ❤️ Chúc vợ chồng Thảo có một mái ấm trọn vẹn, cùng nhau hạnh phúc tới tuổi răng long đầu đạc nhá 💒💒"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Tiểu Oanh 
                            </span>
                            <blockquote>
                              <p>
                                "Gửi đến cô bé có cặp răng khểnh đáng yêu nhất trái đất. Chị vẫn luôn tin 1 cô bé có nhiều năng lượng tích cực như em sẽ được hạnh phúc và yêu thương. Chúc 2 em giữ mãi đc nhiệt huyết và thanh xuân của tuổi trẻ, hãy để cho "bà chị" bi quan tin rằng, tình yêu và hạnh phúc sẽ đến với tất cả mọi người - không chỉ riêng em. HPWD Thảo Lương & An 🍀♥️🍓👰‍♀️👩‍❤️‍👨."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Thảo Nguyên A1 
                            </span>
                            <blockquote>
                              <p>
                                "Mới ngày nào còn là tình yêu học trò mà giờ đã nên duyên vợ chồng rồi. Chúc cho vợ chồng bạn Thảo và anh An hạnh phúc trọn vẹn bên nhau mãi mãi ❤️."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Mai Kiều Oanhhhh
                            </span>
                            <blockquote>
                              <p>
                                "Chúc cho bạn An và em Thảo thật hạnh phúc trăm năm nhé. Chúc 2 vc sẽ luôn bên nhau và nhiều điều may mắn nhất nha ❤️."
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Oanh & Hạnh
                            </span>
                            <blockquote>
                              <p>
                                "Mọi sự cố gắng sẽ luôn được đền đáp xứng đáng. Không phô trương, không ồn ào nhưng đến một thời điểm thích hợp mọi thứ đều toả sáng khiến ai cũng phải trầm trồ, ngưỡng mộ.
                              </p>
                              <p>Chúc cho hành trình mới của An và Thảo sẽ luôn tràn ngập niềm vui và hạnh phúc.</p>
                              <p>Trai 97 thương vợ miễn bàn cô dâu cứ yên tâm nhé 😊."</p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Cẩm Anh xinh đẹp tuyệt vời
                            </span>
                            <blockquote>
                              <p>
                                "T còn tưởng m định k chịu lấy ck cơ Chúc mừng hạnh phúc nhé"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Bắp
                            </span>
                            <blockquote>
                              <p>
                                "Thức dậу nhìn thấу nhau Ϲất lên tiếng gọi mình ơi Nghĩ thôi cũng vui"
                              </p>
                              <p>Thấy hạnh phúc lây vì An Thảo đã nên duyên vợ chồng. Chúc đôi bạn trẻ luôn cười thật tươi như những bức hình xinh đẹp ở đây nhé ^^</p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            em Hoa dễ thưnn (bẹp)
                            </span>
                            <blockquote>
                              <p>
                                "Em xin thành tâm xin vía có một mối tình vừa dễ thương vừa chất lượng như anh chị🥰! Cảm ơn chị Thảo đã chịu hốt ông anh zai này🫰🏻
                              </p>
                              <p>Căn cứ Khoản 1, Điều 19 Luật hôn nhân và gia đình năm 2014 “Vợ chồng có nghĩa vụ thương yêu, chung thủy, tôn trọng, quan tâm, chăm sóc, giúp đỡ nhau; cùng nhau chia sẻ, thực hiện các công việc trong gia đình. Em mong anh chị sẽ mãi mãi ở bên cạnh nhau và thật hạnh phúc ạ! (Phải tuân thủ pháp luật nhé🫶🏻)"</p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Anh Đào
                            </span>
                            <blockquote>
                              <p>
                              "Chúc mừng 2 em viên mãn với chặng đường mới. Hạnh phúc bắt đầu từ thuở thanh mai trúc mã. Thật xúc động với lời yêu thương giản dị mà An dành cho bạn đời của mình. Trân quý!"
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="item one-third">
                          <div className="testimony-slide active text-center">
                            {/* <figure>
                              <img src="images/thao_bean.webp" alt="user" />
                            </figure> */}
                            <span>
                            Em Lệ xinh đẹp
                            </span>
                            <blockquote>
                              <p>
                              "Dear anh chị
                              </p>
                              <p>Trên chặng đường mới mong chị iu của em luôn vững vàng, luôn được yêu thương, mọi điều đến với chị luôn nhẹ nhàng heng!</p>
                              <p>Chúc anh chị hạnh phúc ❤️ "</p>
                            </blockquote>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="fh5co-started" className="fh5co-bg" style={{ backgroundImage: 'url(images/img_bg_4.jpg)' }}>
            <div className="overlay" />
            <div className="container">
              <div className="row animate-box">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                  <h2>Gui loi chuc</h2>
                  <p>Cảm ơn bạn rất nhiều vì đã gửi những lời chúc mừng tốt đẹp nhất đến đám cưới của chúng mình!</p>
                </div>
              </div>
              <div className="row animate-box">
                <div className="col-md-10 col-md-offset-1">
                  <div className="form-inline">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name" className="sr-only">
                          Tên
                        </label>
                        <input type="name" className="form-control" id="name" placeholder="Tên của bạn" onChange={handleNameChange} value={name}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="sr-only">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Email của bạn" onChange={handleEmailChange} value={email}/>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="sr-only">Lời chúc</label>
                        <textarea rows="4" type="message" className="form-control" id="message" placeholder="Lời chúc của bạn" onChange={handleMessageChange} value={message}/>
                        {/* <label htmlFor="message" /> */}
                        <label style={{color: "#fff"}}>{status}</label>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 text-center send-btn">
                      <div className="col-md-6 col-sm-6">
                        <button disabled={name === "" || email === "" || message === ""} className="btn btn-default btn-block" onClick={submitMessage}>
                          Gửi
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Play music button */}
        <div className="play-music js-top">
          <a onClick={togglePlay} style={{cursor:"pointer"}}>
            {/* Render volume or mute icon based on playing state */}
            <i className={`icon-${!isPlaying ? 'mute' : 'volume'}`} />
          </a>
        </div>
        {/* Gift button */}
        <div className="wd-gift">
          <a onClick={giftOpen} style={{cursor:"pointer"}}>
            {/* Render volume or mute icon based on playing state */}
            {/* <i className={`icon-heart`} /> */}
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1.3 1.3" enableBackground="new 0 0 52 52" xmlSpace="preserve">
              <path d="M0.513 0.228c0.005 0.015 0.02 0.023 0.035 0.023H0.75c0.015 0 0.03 -0.007 0.035 -0.023l0.08 -0.148c0.005 -0.015 -0.005 -0.03 -0.02 -0.03H0.455c-0.015 0 -0.025 0.015 -0.017 0.028z"/>
              <path d="M0.768 0.367h-0.235C0.335 0.367 0.175 0.53 0.175 0.73v0.4c0 0.065 0.053 0.12 0.12 0.12h0.71c0.065 0 0.12 -0.055 0.12 -0.12v-0.4c0 -0.2 -0.163 -0.363 -0.358 -0.363m-0.057 0.675v0.068c0 0.013 -0.013 0.02 -0.025 0.02h-0.08c-0.013 0 -0.015 -0.007 -0.015 -0.02v-0.065c-0.06 -0.013 -0.11 -0.038 -0.123 -0.05 -0.015 -0.015 -0.02 -0.028 -0.007 -0.045l0.025 -0.04c0.005 -0.01 0.017 -0.015 0.03 -0.015 0.007 0 0.015 0.003 0.02 0.005h0.003c0.04 0.025 0.075 0.035 0.1 0.035 0.028 0 0.05 -0.015 0.05 -0.03 0 -0.013 -0.007 -0.033 -0.083 -0.057 -0.068 -0.025 -0.15 -0.065 -0.15 -0.158 0 -0.055 0.035 -0.118 0.135 -0.138v-0.06c0 -0.013 0.005 -0.02 0.015 -0.02h0.08c0.013 0 0.025 0.007 0.025 0.02V0.55c0.04 0.01 0.083 0.03 0.098 0.04 0.007 0.005 0.013 0.015 0.015 0.025s-0.003 0.02 -0.007 0.025L0.78 0.675c-0.007 0.01 -0.023 0.017 -0.033 0.017 -0.005 0 -0.013 -0.003 -0.017 -0.005 -0.04 -0.023 -0.072 -0.035 -0.095 -0.035 -0.033 0 -0.048 0.015 -0.048 0.025 0 0.015 0.007 0.03 0.075 0.055 0.083 0.028 0.175 0.072 0.175 0.168 0.003 0.065 -0.05 0.123 -0.128 0.143"/>
            </svg>
          </a>
        </div>
        {/* Message button */}
        <div className="wd-message">
          <a onClick={() => handleItemClick('loichuc')} style={{cursor:"pointer"}}>
            {/* Render volume or mute icon based on playing state */}
            <i className={`icon-pencil`} />
          </a>
        </div>
        {/* Audio element */}
        <audio ref={audioRef} loop autoPlay>
          <source id="src_mp3" type="audio/mp3" src={mp3_file}/>
          <source id="src_ogg" type="audio/ogg" src=""/>
          <object id="audio_object" type="audio/x-mpeg" width="200px" height="45px" data={mp3_file}>
              <param id="param_src" name="src" value={mp3_file} />
              <param id="param_src" name="src" value={mp3_file} />
              <param name="autoplay" value="true" />
              <param name="autostart" value="true" />
          </object>
        </audio> 
        <div className="gototop js-top">
          <a href="#" className="js-gotop">
            <i className="icon-arrow-up"></i>
          </a>
        </div>
      </>
      {/* Dialog container */}
      {dialogOpen && (
        <div ref={donatePopupRef} id="donate-modal" className="donate-modal" style={{ display: "block" }}>
          <div className="donate-modal-content">
            <div className="donate-modal-header">
              <span className="donate-modal-close" onClick={() => setDialogOpen(false)}>×</span>
              <strong>
              <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1.3 1.3" enableBackground="new 0 0 52 52" xmlSpace="preserve">
                <path d="M0.513 0.228c0.005 0.015 0.02 0.023 0.035 0.023H0.75c0.015 0 0.03 -0.007 0.035 -0.023l0.08 -0.148c0.005 -0.015 -0.005 -0.03 -0.02 -0.03H0.455c-0.015 0 -0.025 0.015 -0.017 0.028z"/>
                <path d="M0.768 0.367h-0.235C0.335 0.367 0.175 0.53 0.175 0.73v0.4c0 0.065 0.053 0.12 0.12 0.12h0.71c0.065 0 0.12 -0.055 0.12 -0.12v-0.4c0 -0.2 -0.163 -0.363 -0.358 -0.363m-0.057 0.675v0.068c0 0.013 -0.013 0.02 -0.025 0.02h-0.08c-0.013 0 -0.015 -0.007 -0.015 -0.02v-0.065c-0.06 -0.013 -0.11 -0.038 -0.123 -0.05 -0.015 -0.015 -0.02 -0.028 -0.007 -0.045l0.025 -0.04c0.005 -0.01 0.017 -0.015 0.03 -0.015 0.007 0 0.015 0.003 0.02 0.005h0.003c0.04 0.025 0.075 0.035 0.1 0.035 0.028 0 0.05 -0.015 0.05 -0.03 0 -0.013 -0.007 -0.033 -0.083 -0.057 -0.068 -0.025 -0.15 -0.065 -0.15 -0.158 0 -0.055 0.035 -0.118 0.135 -0.138v-0.06c0 -0.013 0.005 -0.02 0.015 -0.02h0.08c0.013 0 0.025 0.007 0.025 0.02V0.55c0.04 0.01 0.083 0.03 0.098 0.04 0.007 0.005 0.013 0.015 0.015 0.025s-0.003 0.02 -0.007 0.025L0.78 0.675c-0.007 0.01 -0.023 0.017 -0.033 0.017 -0.005 0 -0.013 -0.003 -0.017 -0.005 -0.04 -0.023 -0.072 -0.035 -0.095 -0.035 -0.033 0 -0.048 0.015 -0.048 0.025 0 0.015 0.007 0.03 0.075 0.055 0.083 0.028 0.175 0.072 0.175 0.168 0.003 0.065 -0.05 0.123 -0.128 0.143"/>
              </svg>                
                <span>Hộp mừng cưới</span>
              </strong>
            </div>
            <div className="donate-modal-body">
              <div className="donate-box">
                <div className="donate-card">
                  <h3>Mừng cưới đến chú rể</h3>
                  <img
                    className="qr-code-image"
                    src="images/an_tech.png"
                  />
                  <p>
                    Ngân hàng: <strong>Techcombank</strong>
                  </p>
                  <p>
                    Tên tài khoản: <strong>Phung Thanh An</strong>
                  </p>
                  <p>
                    Số tài khoản: <strong>1903 9210 3720 10</strong>
                  </p>
                  <p>
                    Chi nhánh: <strong>Quận 7</strong>
                  </p>
                </div>
                <div className="donate-card">
                  <h3>Mừng cưới đến cô dâu</h3>
                  <img
                    className="qr-code-image"
                    src="images/thao_tech.png"
                  />
                  <p>
                    Ngân hàng: <strong>Techcombank</strong>
                  </p>
                  <p>
                    Tên tài khoản: <strong>Luong Thi Thao</strong>
                  </p>
                  <p>
                    Số tài khoản: <strong>1903 8256 6590 16</strong>
                  </p>
                  <p>
                    Chi nhánh: <strong>Quận 2</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenYoutube && (
        <div ref={youtubePopupRef} id="donate-modal" className="youtube-modal" style={{ display: "block" }}>
          <div className="youtube-modal-content">
          <ReactPlayer height={360} width={"100%"} url='https://www.youtube.com/watch?v=taf5psH3c8s'/>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
