export const slides = [
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/814f3814e60a4954101b.jpg?alt=media&token=e975ce31-e0e4-4776-a455-9fc85045cb75',
    title: 'Slide Title One',
    description: 'Slide Description One\n\nthis is new line',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_94435660897e26207f6f.jpg?alt=media&token=1af7d978-2764-41a1-a75f-6b4c52f4f49f',
    title: 'Slide Title Three',
    description: 'Slide Description Three',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_7b0d5d2a82342d6a7425.jpg?alt=media&token=04615144-d134-4616-8372-17952971033f',
    title: 'Slide Title Four',
    description: 'Slide Description Four',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_7945578e889027ce7e81.jpg?alt=media&token=6c899677-ede8-4138-9e19-41c6b17f6ed8',
    title: 'Slide Title Five',
    description: 'Slide Description Five',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_63751c9ac3846cda3595.jpg?alt=media&token=9b052d91-414f-4cec-8da9-76a05215f69a',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_192d3352ec4c43121a5d.jpg?alt=media&token=c163983e-1eeb-4e59-bae5-276b9c19e143',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_944a19adc6b369ed30a2.jpg?alt=media&token=3ee13011-2526-43ee-9a67-a46d6e89b30a',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_c8e63b18e4064b581217.jpg?alt=media&token=82b5bb05-33ac-4f3d-b690-216462cbf6e8',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c1cf4d0b92153d4b6404.jpg?alt=media&token=54431aac-06d3-452b-b116-6377cc98d965',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_d5b84974966a3934607b.jpg?alt=media&token=8c2c8cfd-6936-41e5-85fb-2024a8babfab',
    title: 'Slide Title One',
    description: 'Slide Description One\n\nthis is new line',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_447d754eaa50050e5c41.jpg?alt=media&token=f687e745-7e9a-4810-9980-57bc0cbdeb7c',
    title: 'Slide Title Eight',
    description: 'Slide Description Eight',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_54aec7491857b709ee46.jpg?alt=media&token=43776304-fdcc-4eba-85a0-a85acee5d41f',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_9a4c9b664478eb26b269.jpg?alt=media&token=9b7127ed-e8af-4d4f-91d3-183b0c3215a7',
//     title: 'Slide Title Two',
//     description: 'Slide Description Two',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_6feafcfd23e38cbdd5f2.jpg?alt=media&token=e1709567-a71b-4616-96aa-64737100d6ce',
//     title: 'Slide Title Six',
//     description: 'Slide Description Six',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_31e8a5fa7ae4d5ba8cf5.jpg?alt=media&token=bd85a30a-c729-4a04-9969-0de70c77166f',
//     title: 'Slide Title Nine',
//     description: 'Slide Description Nine',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_0141816f5e71f12fa860.jpg?alt=media&token=596aaadf-4951-4c3f-9d58-4dcb573487d3',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_f6c9ccc113dfbc81e5ce.jpg?alt=media&token=98451d30-241d-4f98-96a3-d6e4484da207',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_1d5fa490fc8f53d10a9e.jpg?alt=media&token=26674346-f00a-4cfe-9000-b0000f30030b',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_7605f4d92bc78499ddd6.jpg?alt=media&token=7c0bb31a-df8b-461e-a6c6-85e7edeae47d',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_938210fd6ce2c3bc9af3.jpg?alt=media&token=a72465d2-73c2-47c6-8527-2e1729782773',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c3338d44525afd04a44b.jpg?alt=media&token=3433ceb7-f034-4a1a-8285-af5ba13a4f6b',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c9e1b7ea68f4c7aa9ee5.jpg?alt=media&token=34f48ec9-5300-49c8-89bd-31d6b10215ce',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_ceebcfbe94a13bff62b0.jpg?alt=media&token=e6be4569-be54-433d-b3e5-3114d1f28157',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_d749728ead9002ce5b81.jpg?alt=media&token=5cc2ed7c-7389-4560-91dd-ced7f5af409b',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_722d91d44ecae194b8db.jpg?alt=media&token=0ce8b98e-fb48-4905-a8f6-95f56a0e0f47',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_b0a43056ef4840161959.jpg?alt=media&token=3d61e145-2f8e-48a2-bfb2-1f5f221322f3',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
//   {
//     src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_f42008c1d7df788121ce.jpg?alt=media&token=ab832cdc-495f-4005-8e0e-6fe06370f3ae',
//     title: 'Slide Title Ten',
//     description: 'Slide Description Ten',
//   },
];

export const detail_slides = [
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/814f3814e60a4954101b.jpg?alt=media&token=e975ce31-e0e4-4776-a455-9fc85045cb75',
    title: 'Slide Title One',
    description: 'Slide Description One\n\nthis is new line',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_94435660897e26207f6f.jpg?alt=media&token=1af7d978-2764-41a1-a75f-6b4c52f4f49f',
    title: 'Slide Title Three',
    description: 'Slide Description Three',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_7b0d5d2a82342d6a7425.jpg?alt=media&token=04615144-d134-4616-8372-17952971033f',
    title: 'Slide Title Four',
    description: 'Slide Description Four',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_7945578e889027ce7e81.jpg?alt=media&token=6c899677-ede8-4138-9e19-41c6b17f6ed8',
    title: 'Slide Title Five',
    description: 'Slide Description Five',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_63751c9ac3846cda3595.jpg?alt=media&token=9b052d91-414f-4cec-8da9-76a05215f69a',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_192d3352ec4c43121a5d.jpg?alt=media&token=c163983e-1eeb-4e59-bae5-276b9c19e143',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_944a19adc6b369ed30a2.jpg?alt=media&token=3ee13011-2526-43ee-9a67-a46d6e89b30a',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_c8e63b18e4064b581217.jpg?alt=media&token=82b5bb05-33ac-4f3d-b690-216462cbf6e8',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c1cf4d0b92153d4b6404.jpg?alt=media&token=54431aac-06d3-452b-b116-6377cc98d965',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_d5b84974966a3934607b.jpg?alt=media&token=8c2c8cfd-6936-41e5-85fb-2024a8babfab',
    title: 'Slide Title One',
    description: 'Slide Description One\n\nthis is new line',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_447d754eaa50050e5c41.jpg?alt=media&token=f687e745-7e9a-4810-9980-57bc0cbdeb7c',
    title: 'Slide Title Eight',
    description: 'Slide Description Eight',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_54aec7491857b709ee46.jpg?alt=media&token=43776304-fdcc-4eba-85a0-a85acee5d41f',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_9a4c9b664478eb26b269.jpg?alt=media&token=9b7127ed-e8af-4d4f-91d3-183b0c3215a7',
    title: 'Slide Title Two',
    description: 'Slide Description Two',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_6feafcfd23e38cbdd5f2.jpg?alt=media&token=e1709567-a71b-4616-96aa-64737100d6ce',
    title: 'Slide Title Six',
    description: 'Slide Description Six',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_31e8a5fa7ae4d5ba8cf5.jpg?alt=media&token=bd85a30a-c729-4a04-9969-0de70c77166f',
    title: 'Slide Title Nine',
    description: 'Slide Description Nine',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_0141816f5e71f12fa860.jpg?alt=media&token=596aaadf-4951-4c3f-9d58-4dcb573487d3',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/b_f6c9ccc113dfbc81e5ce.jpg?alt=media&token=98451d30-241d-4f98-96a3-d6e4484da207',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_1d5fa490fc8f53d10a9e.jpg?alt=media&token=26674346-f00a-4cfe-9000-b0000f30030b',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_7605f4d92bc78499ddd6.jpg?alt=media&token=7c0bb31a-df8b-461e-a6c6-85e7edeae47d',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_938210fd6ce2c3bc9af3.jpg?alt=media&token=a72465d2-73c2-47c6-8527-2e1729782773',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c3338d44525afd04a44b.jpg?alt=media&token=3433ceb7-f034-4a1a-8285-af5ba13a4f6b',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_c9e1b7ea68f4c7aa9ee5.jpg?alt=media&token=34f48ec9-5300-49c8-89bd-31d6b10215ce',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_ceebcfbe94a13bff62b0.jpg?alt=media&token=e6be4569-be54-433d-b3e5-3114d1f28157',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/w_d749728ead9002ce5b81.jpg?alt=media&token=5cc2ed7c-7389-4560-91dd-ced7f5af409b',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_722d91d44ecae194b8db.jpg?alt=media&token=0ce8b98e-fb48-4905-a8f6-95f56a0e0f47',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_b0a43056ef4840161959.jpg?alt=media&token=3d61e145-2f8e-48a2-bfb2-1f5f221322f3',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
  {
    src: 'https://firebasestorage.googleapis.com/v0/b/anthaowd.appspot.com/o/f_f42008c1d7df788121ce.jpg?alt=media&token=ab832cdc-495f-4005-8e0e-6fe06370f3ae',
    title: 'Slide Title Ten',
    description: 'Slide Description Ten',
  },
];
